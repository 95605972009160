import crossIcon from "./images/cross.png";
import plusIcon from "./images/plus.png";
import fireplaceIcon from "./images/fireplace.png";
import uparrowIcon from "./images/uparrow.png";
import pyramidIcon from "./images/pyramid.png";
import diamondIcon from "./images/diamond.png";
import solitaireIcon from "./images/solitaire.png";

export const SIZE = 7;
export const BOARD_SIZE = 700;

export const ICONS = {
  cross: crossIcon,
  plus: plusIcon,
  fireplace: fireplaceIcon,
  uparrow: uparrowIcon,
  pyramid: pyramidIcon,
  diamond: diamondIcon,
  solitaire: solitaireIcon,
};

export const TEMPLATES = {
  cross: [
    ["X", "X", "0", "0", "0", "X", "X"],
    ["X", "X", "0", "0", "0", "X", "X"],
    ["0", "0", "0", "1", "0", "0", "0"],
    ["0", "0", "1", "1", "1", "0", "0"],
    ["0", "0", "0", "1", "0", "0", "0"],
    ["X", "X", "0", "1", "0", "X", "X"],
    ["X", "X", "0", "0", "0", "X", "X"],
  ],
  plus: [
    ["X", "X", "0", "0", "0", "X", "X"],
    ["X", "X", "0", "1", "0", "X", "X"],
    ["0", "0", "0", "1", "0", "0", "0"],
    ["0", "1", "1", "1", "1", "1", "0"],
    ["0", "0", "0", "1", "0", "0", "0"],
    ["X", "X", "0", "1", "0", "X", "X"],
    ["X", "X", "0", "0", "0", "X", "X"],
  ],
  fireplace: [
    ["X", "X", "1", "1", "1", "X", "X"],
    ["X", "X", "1", "1", "1", "X", "X"],
    ["0", "0", "1", "1", "1", "0", "0"],
    ["0", "0", "1", "0", "1", "0", "0"],
    ["0", "0", "0", "0", "0", "0", "0"],
    ["X", "X", "0", "0", "0", "X", "X"],
    ["X", "X", "0", "0", "0", "X", "X"],
  ],
  uparrow: [
    ["X", "X", "0", "1", "0", "X", "X"],
    ["X", "X", "1", "1", "1", "X", "X"],
    ["0", "1", "1", "1", "1", "1", "0"],
    ["0", "0", "0", "1", "0", "0", "0"],
    ["0", "0", "0", "1", "0", "0", "0"],
    ["X", "X", "1", "1", "1", "X", "X"],
    ["X", "X", "1", "1", "1", "X", "X"],
  ],
  pyramid: [
    ["X", "X", "0", "0", "0", "X", "X"],
    ["X", "X", "0", "1", "0", "X", "X"],
    ["0", "0", "1", "1", "1", "0", "0"],
    ["0", "1", "1", "1", "1", "1", "0"],
    ["1", "1", "1", "1", "1", "1", "1"],
    ["X", "X", "0", "0", "0", "X", "X"],
    ["X", "X", "0", "0", "0", "X", "X"],
  ],
  diamond: [
    ["X", "X", "0", "1", "0", "X", "X"],
    ["X", "X", "1", "1", "1", "X", "X"],
    ["0", "1", "1", "1", "1", "1", "0"],
    ["1", "1", "1", "1", "1", "1", "1"],
    ["0", "1", "1", "1", "1", "1", "0"],
    ["X", "X", "1", "1", "1", "X", "X"],
    ["X", "X", "0", "1", "0", "X", "X"],
  ],
  solitaire: [
    ["X", "X", "1", "1", "1", "X", "X"],
    ["X", "X", "1", "1", "1", "X", "X"],
    ["1", "1", "1", "1", "1", "1", "1"],
    ["1", "1", "1", "0", "1", "1", "1"],
    ["1", "1", "1", "1", "1", "1", "1"],
    ["X", "X", "1", "1", "1", "X", "X"],
    ["X", "X", "1", "1", "1", "X", "X"],
  ],
};
